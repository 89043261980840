import $axios from '@/utils/request'

// 审核补课视频列表
export function getListVideoReview (data) {
  const url = '/courseUnit/listVideoReview'
  return $axios.fPost(url, data)
}

// 老师的审核补课视频列表
export function listVideoReviewByOperator (data) {
  const url = '/courseUnit/listVideoReviewByOperator'
  return $axios.fPost(url, data)
}

// 补课审核
export function reviewAttachment (data) {
  const url = '/courseUnit/reviewAttachment'
  return $axios.fPost(url, data)
}

// 老师排课列表
export function getLessonList (data) {
  const url = '/teacherLesson/list'
  return $axios.fPost(url, data)
}

// 账户可查看校区的排课列表
export function getLessonlistMyCampus (data) {
  const url = '/teacherLesson/listMyCampus'
  return $axios.fPost(url, data)
}

// 老师全部的排课列表
export function getLessonlistAll (data) {
  const url = '/teacherLesson/listAll'
  return $axios.fPost(url, data)
}

// 查询老师所在得排课的班级列表
export function getTeacherClassByUser (data) {
  const url = '/classlession/getTeacherClassByUser'
  return $axios.fPost(url, data)
}

// 查询老师所在得排课的班级的题库
export function getTeacherClassContext (data) {
  const url = '/classlession/getTeacherClassContext'
  return $axios.fGet(url, data)
}

// 批量审核补课视频
export function reviewAttachmentBat (data) {
  const url = '/courseUnit/reviewAttachmentBat'
  return $axios.fPost(url, data)
}

// 开放某时段的排课的补课视频给成员
export function setAttachmentToUserBat (data) {
  const url = '/courseUnit/setAttachmentToUserBat'
  return $axios.fPost(url, data)
}

// 更新排课老师（主教、助教）
export function replaceTeacher (data) {
  const url = '/lesson/replaceTeacher'
  return $axios.fPost(url, data)
}

// 更新排课学员
export function replaceStudent (data) {
  const url = '/lesson/replaceStudent'
  return $axios.fPost(url, data)
}

// 新增排课
export function lessonAdd (data) {
  const url = '/lesson/add'
  return $axios.fPost(url, data)
}

// 编辑排课
export function lessonEdit (data) {
  const url = '/lesson/edit'
  return $axios.fPost(url, data)
}

// 排课排序
export function reSortLesson (data) {
  const url = '/lesson/reSort'
  return $axios.fPost(url, data)
}

// 获取排课信息
export function getLessonInfo (data) {
  const url = '/classlession/getLessonInfo'
  return $axios.fGet(url, data)
}

// 获取排课的考试信息列表
export function listUserExamByLesson (data) {
  const url = '/exam/listUserExamByLesson'
  return $axios.fPost(url, data)
}

// 公布考试结果
export function examAnnounce (data) {
  const url = '/exam/announce'
  return $axios.fPost(url, data)
}

// 关联课节
export function LessonMapCourseUnits (data) {
  const url = '/lesson/mapCourseUnits'
  return $axios.fPost(url, data)
}

// 获取排课耗时信息列表
export function getListLessonConsume (data) {
  const url = '/class/listLessonConsume'
  return $axios.fGet(url, data)
}

// 编辑排课课消数
export function editConsumeBat (data) {
  const url = '/lesson/editConsumeBat'
  return $axios.fPost(url, data)
}

// 课堂反馈
export function feedback (data) {
  const url = '/lesson/feedback'
  return $axios.fPost(url, data)
}

// 获取课堂反馈
export function getFeedback (data) {
  const url = '/lesson/getFeedback'
  return $axios.fGet(url, data)
}
